import React, { useCallback, useRef, useContext, type FC } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { SpotlightTarget } from '@atlaskit/onboarding';

import {
	FlyoutMenuItem,
	FlyoutMenuItemContent,
	FlyoutMenuItemTrigger,
} from '@atlassian/navigation-system/side-nav/flyout-menu-item';

import { preloadSpacesList } from '@confluence/space-utils/entry-points/preloadSpacesList';
import { usePageSpaceKey } from '@confluence/page-context';
import { useSessionData } from '@confluence/session-data';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { useRouteDataRef, useRouteName } from '@confluence/route-manager';
import { SpacesDropdownLoader } from '@confluence/spaces-dropdown';
import {
	APP_NAV_SPACES_DROPDOWN_EXPERIENCE,
	ExperienceTrackerContext,
	ExperienceTimeout,
} from '@confluence/experience-tracker';
import { SPACE_DIRECTORY } from '@confluence/named-routes';

import { i18n } from '../globalNavigationTranslations';
import { GlobeIconComponent } from '../GlobalNavigationIcons';
import { useFlyout } from '../FlyoutStore';

import type { GlobalItemProps } from './globalItemProps';
import { useGlobalItemVisibility } from './useGlobalItemVisibility';

const MENU_ID = 'spaces';

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ '../analyticsCallbacks'))
			.fireSpacesClickedAnalytics,
);

export const SpacesItem: FC<GlobalItemProps> = ({ isHidden, peekingId, setPeekingId }) => {
	const { isFlyoutOpen, openFlyout, closeFlyout } = useFlyout(MENU_ID);

	const routeDataRef = useRouteDataRef();
	const [spaceKey] = usePageSpaceKey();
	const selectedSpaceKey: string | undefined = spaceKey;
	const { isLicensed } = useSessionData();

	const isMounted = useRef(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);
	const experienceTracker = useContext(ExperienceTrackerContext);

	const abortExperienceTracker = useCallback(() => {
		experienceTracker.abort({
			name: APP_NAV_SPACES_DROPDOWN_EXPERIENCE,
			reason: 'Primary dropdown menu: closed by user',
			attributes: {
				navVersion: '4',
				dropdownType: 'spaces',
			},
		});
	}, [experienceTracker]);

	const startExperienceTracker = useCallback(() => {
		experienceTracker.start({
			name: APP_NAV_SPACES_DROPDOWN_EXPERIENCE,
			timeout: ExperienceTimeout.NAVIGATION_LOAD,
			attributes: {
				navVersion: '4',
				dropdownType: 'spaces',
			},
		});
	}, [experienceTracker]);

	const onOpenChange = useCallback(
		(newIsOpen: boolean) => {
			if (!isMounted.current) {
				isMounted.current = true;
				return;
			}
			if (!newIsOpen) {
				abortExperienceTracker();
				void fireClickAnalytics(false);
				isFlyoutOpen && closeFlyout();
			} else {
				openFlyout();
			}
		},
		[abortExperienceTracker, fireClickAnalytics, isFlyoutOpen, openFlyout, closeFlyout],
	);

	const onClick = useCallback(() => {
		if (!isFlyoutOpen) {
			openFlyout();
			startExperienceTracker();
			void fireClickAnalytics(true);
		} else {
			closeFlyout();
		}
	}, [fireClickAnalytics, isFlyoutOpen, startExperienceTracker, openFlyout, closeFlyout]);

	const onMouseEnter = useCallback(() => {
		if (!isFlyoutOpen) {
			void SpacesDropdownLoader.preload();
			if (selectedSpaceKey) {
				void preloadSpacesList({
					isLicensed,
					selectedSpaceKey,
				});
			}
		}
	}, [isFlyoutOpen, isLicensed, selectedSpaceKey]);

	const isSelected = useRouteName(isOnSpacesRoute);
	const shouldHide = useGlobalItemVisibility(
		MENU_ID,
		isSelected || isFlyoutOpen,
		isHidden,
		peekingId,
		setPeekingId,
	);

	if (shouldHide) {
		return null;
	}

	return (
		<>
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<div onMouseEnter={onMouseEnter}>
				<SpotlightTarget name="spaces-item-spotlight">
					<FlyoutMenuItem onOpenChange={onOpenChange} isOpen={isFlyoutOpen}>
						<FlyoutMenuItemTrigger
							elemBefore={GlobeIconComponent}
							onClick={onClick}
							isSelected={isSelected}
						>
							<FormattedMessage {...i18n.spaces} />
						</FlyoutMenuItemTrigger>
						<FlyoutMenuItemContent onClose={() => isFlyoutOpen && closeFlyout()} autoFocus={false}>
							<Box xcss={fixFocus}>
								<SpacesDropdownLoader isNav4Enabled />
							</Box>
						</FlyoutMenuItemContent>
					</FlyoutMenuItem>
				</SpotlightTarget>
			</div>
		</>
	);
};

const fixFocus = xcss({
	//@ts-expect-error styling is applying but property does not exist for SafeCSSObject
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	a: {
		':focus-visible': {
			outlineOffset: `${token('space.negative.025')}`,
		},
	},
});

const isOnSpacesRoute = {
	selector: (routeName: string | undefined) => routeName === SPACE_DIRECTORY.name,
};
